import React from 'react'
import Navigation from "../navigation/navigation";

class Header extends React.Component {
    render() {
        return (
            <header className="page-header">
                <Navigation/>
                {this.props.children}
            </header>
        )
    }
}

export default Header
