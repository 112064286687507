import React from 'react'
import { Link } from 'gatsby'
import './style.css'

export default class Footer extends React.Component {
  render() {
    return (
      <footer className='page-footer footer-centered text-center'>
        <section className='footer-content'>
          <div className='container'>
            <div className='navbar-brand'>
              <Link to='/'><img src='/images/ETWDC_LOGO.svg' alt='' height='100' /></Link>
            </div>
            <p className='big'>
              <br />Laying the foundation for a new beginning.<br /><br />
              <Link to='/contact-us'>CONTACT US</Link>
              <br /><br />
              <a rel="noreferrer" href='https://instagram.com/etwdc?igshid=18x3bhnbw9wi0' target={'_blank'}
                 className={'social-media-link'}>
                <img src='/images/insta.svg' alt='Instagram' width='47' height='47' />
              </a>
              <a rel="noreferrer" href='https://www.facebook.com/etwdc/' target={'_blank'} className={'social-media-link'}>
                <img src='/images/facebook.svg' alt='Facebook' width='47' height='47' />
              </a>
              <a rel="noreferrer" href='https://twitter.com/etwdevelopment' target={'_blank'} className={'social-media-link'}>
                <img src='/images/twitter.svg' alt='Twitter' width='47' height='47' />
              </a>
              <a rel="noreferrer" href='https://www.linkedin.com/company/east-to-west-development-corporation/' target={'_blank'}
                 className={'social-media-link'}>
                <img src='/images/LinkedIn.svg' alt='LinkedIn' width='47' height='47' />
              </a>
            </p>
          </div>
        </section>
        <section className='copyright'>
          <div className='container'>
            <p>&#169; <span className='copyright-year' /> All Rights Reserved <Link to='terms'>Terms of
              Use and
              Privacy Policy</Link></p>
          </div>
        </section>
      </footer>
    )
  }
}

