import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import './style.css'

const activeStyle = {
    color: 'var(--primary-color)',
}

class Navigation extends React.Component {
    renderProjectsList() {
        return <StaticQuery query={query} render={data => {
            return (
                <>
                    {!data && <p>Loading...</p>}
                    {data &&
                        data.allContentfulPage.nodes.map(project => {
                            return <Link key={project.slug} activeStyle={activeStyle} className="dropdown-item" to={'/' + project.slug}>{project.title}</Link>
                        })
                    }
                </>
            )
        }}/>
    }

    render() {
        return (
            <div className={'rd-navbar-wrap'}>
                <nav className={'rd-navbar top-panel-none-items rd-navbar-original rd-navbar-static'}>
                    <div className={'rd-navbar-inner'}>
                        <div className={'rd-navbar-panel'}>
                            <button className='rd-navbar-toggle toggle-original' data-rd-navbar-toggle='.rd-navbar'>
                                <span/>
                            </button>
                            <div className='rd-navbar-brand'>
                                <Link className='brand-name' to='/'>
                                    <img id='logo' src='/images/ETWDC_LOGO2.svg' alt='' height='50'/>
                                    <img id='logoM' src='/images/ETWDC_LOGO5.svg' alt='' height='30'/>
                                </Link>
                            </div>
                        </div>
                        <div className={'rd-navbar-nav-wrap'}>
                            <a className='donate-cta button button-secondary button-xs'
                               href={'https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=M8CDH3J7EXC58'}
                               rel={'noreferrer'} target={'_blank'}>DONATE</a>
                            <ul className='rd-navbar-nav'>
                                <li><Link activeStyle={activeStyle} to={'/about'}>ABOUT US</Link></li>
                                <li><Link activeStyle={activeStyle} to={'/facts'}>THE FACTS</Link></li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle project-link" href="#" id="navbarDropdown" role="button"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        PROJECTS
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {this.renderProjectsList()}
                                    </div>
                                </li>
                                <li><Link activeStyle={activeStyle} to={'/involved'}>GET INVOLVED</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navigation

const query = graphql`
{
  allContentfulPage(filter: {type: {eq: "Project"}}) {
    totalCount
    nodes {
      title
      slug
    } 
  }
}
`;